// ==========================================================================
// Foundation
// ==========================================================================

//@import "foundation/_normalize"; //動的のノーマライズを使うため使用しない
//@import "foundation/_easing"; //不要
@import "../foundation/_variable";
@import "../foundation/_mixins";
//@import "foundation/_font"; //動的のフォント定義に合わせるため使用しない

footer.footer {
  @media (max-width: $BREAKPOINT) {
    width: 100%;
    min-width: initial;
  }
}
#include-footer {
  min-width: $MAX-CONTENT-WIDTH;
  background-color: #3e3e3e;
  padding-top: 45px;
  position: relative;
  z-index: 900;
  @media (max-width: $BREAKPOINT) {
    padding: 0;
    min-width: initial;
  }
}

#foot-item,
.ix-l-footer {
  background-color: #3e3e3e;
  a {
    text-decoration: none;
    &:hover {
      color: #9fa0a0;
      text-decoration: none;
    }
  }
  * {
    //動的の旧デザインフッターの影響を避けるためリセット
    color: #fff;
    font-family: "YuGothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック",
      "游ゴシック体", "Noto Sans Japanese Light", sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  .ft-row .inner {
    @media (max-width: $BREAKPOINT) {
      width: 100%;
      min-width: initial;
    }
  }
  .f-bg-color {
    padding-top: 45px;
    background: {
      color: #3e3e3e;
    }
    @media (max-width: $BREAKPOINT) {
      padding: 0;
      display: block;
    }
    .ft-row {
      width: 1056px;
      margin: 0 auto;
      @media (max-width: $BREAKPOINT) {
        width: 100%;
        min-width: initial;
        margin-bottom: 0 !important;
      }
    }
  }
  #pagetop-pc {
    width: 50px;
    height: 50px;
    bottom: 58px;
  }
}
.ix-l-footer {
  * {
    //動的の旧デザインフッターの影響を避けるためリセット
    color: #fff;
    font-family: "YuGothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック",
      "游ゴシック体", "Noto Sans Japanese Light", sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  .ix-l-footer-1 {
    width: 1056px;
    margin: 0 auto;
    @media (max-width: $BREAKPOINT) {
      width: 100%;
      min-width: initial;
      margin-bottom: 0 !important;
    }
    .ix-l-footer-1__inner {
      @media (max-width: $BREAKPOINT) {
        padding: 0;
      }
    }
  }
  .p-gnav__col .p-gnav__ttl::after {
    bottom: 3px;
    right: 16px;
  }
}
//パンくずリスト
.p-footer__topicpath {
  margin-bottom: 40px;
}
#f-breadcrumb {
  //font-size: 1.3rem;
  font-size: 13px;
  display: flex;
  @media (max-width: $BREAKPOINT) {
    display: none;
  }
  .f-breadcrumb-item {
    margin-right: 15px;
    a {
      display: flex;
      padding-right: 15px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      bottom: 0;
      right: 0;
      width: 4px;
      height: 4px;
      border-top: 1px solid #bfc0c0;
      border-right: 1px solid #bfc0c0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

//静的環境用
#ix-topicpath-footer {
  //font-size: 1.3rem;
  font-size: 13px;
  display: flex;
  @media (max-width: $BREAKPOINT) {
    display: none;
  }
  ul li {
    margin-right: 15px;
    position: relative;
    a {
      display: flex;
      padding-right: 15px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      bottom: 0;
      right: 0;
      width: 4px;
      height: 4px;
      border-top: 1px solid #bfc0c0;
      border-right: 1px solid #bfc0c0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

//ナビゲーション
#common-full-footer {
  width: 1056px;
  margin: 0 auto;
  @media (max-width: $BREAKPOINT) {
    width: 100%;
  }
  .p-gnav {
    display: flex;
    margin-bottom: 70px;
    @media (max-width: $BREAKPOINT) {
      display: block;
      margin-bottom: 0;
    }
    .p-gnav__col {
      width: 264px;
      @media (max-width: $BREAKPOINT) {
        width: 100%;
        //font-size:1.3rem;
        font-size: 13px;
        border: none;
        // &:first-of-type{
        //   //border-top: 1px solid #4e4e4e;
        // }
        // &:last-of-type{
        //   //border-bottom: none;
        // }
      }
    }
    .p-gnav__ttl {
      //font-size: 1.4rem;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 30px;
      @media (max-width: $BREAKPOINT) {
        padding: 0 10px;
        height: 55px;
        line-height: 55px;
        font-weight: normal;
        margin-bottom: 0;
        border: none;
        border-bottom: 1px solid #4e4e4e;
        position: relative;
        overflow: hidden;
      }
    }
    .p-gnav__list {
      // @media (min-width: $BREAKPOINT) {
      //   display: block !important;
      //   height: auto !important;
      // }
      @media (max-width: $BREAKPOINT) {
        border-bottom: 1px solid #4e4e4e;
        border-top: none;
        display: none;
        padding: 0;
        // &:last-child{
        //   border-bottom: none;
        // }
      }
    }
    .p-gnav__list li {
      // font-size: 1.4rem;
      font-size: 14px;
      margin-bottom: 20px;
      @media (max-width: $BREAKPOINT) {
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      @media (max-width: $BREAKPOINT) {
        margin-bottom: 0;
      }
      a {
        transition: none;
        @media (max-width: $BREAKPOINT) {
          display: block;
          padding: 0 25px;
          height: 45px;
          line-height: 45px;
          //font-size:1.3rem;
          font-size: 13px;
          margin-bottom: 0;
          background-color: #484848;
          border-bottom: none;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: $BREAKPOINT) {
  .p-gnav__col .p-gnav__ttl::after {
    content: "\e908";
    display: block;
    font-size: 12px;
    height: 10px;
    width: 10px;
    color: #727171;
    transform-origin: center;
    transform: rotate(90deg);
    transition: all 0.3s ease 0s;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 10px;
  }
  .p-gnav__col .p-gnav__ttl.is-active::after {
    color: #b99969;
    transform: rotate(-90deg);
  }
}
//企業情報
#common-middle-footer{
  @media (max-width: $BREAKPOINT) {
    display: flex;
    flex-direction: column-reverse;
  }
}
#common-middle-footer-upper {
  background: #3e3e3e;
  margin-bottom: 20px;
  @media (max-width: $BREAKPOINT) {
    margin-bottom: 0;
  }
  .p-footer-nav-2 {
    width: 1056px;
    margin: 0 auto;
    display: flex;
    border: none;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $BREAKPOINT) {
      width: 100%;
      flex-direction: column-reverse;
      min-width: initial;
      height: auto;
      border: none;
    }
    .p-footer-nav-2__col-1 {
      @media (max-width: $BREAKPOINT) {
        padding: 35px 0 20px;
        margin: 0;
        img {
          margin: 0;
        }
      }
    }
    .p-footer-nav-2__col-2 {
      @media (max-width: $BREAKPOINT) {
        width: 100%;
      }
    }
  }
  .c-logo-footer {
    height: auto;
    width: 216px;
    position: static;
    @media (max-width: $BREAKPOINT) {
      width: 144px;
    }
    img{
      width: 100%;
      @include _pc {
        width: 70%;
      }
    }
  }
  .p-fotter-service{
    @media (max-width: $BREAKPOINT) {
      width: 100%;
      padding: 36px 18px;
      border-bottom: 1px solid #4e4e4e;
    }
    &__list{
      display: flex;
      padding-top: 12px;
      @media (max-width: $BREAKPOINT) {
        display: block;
        padding-top: 0px;
      }
    }
    &__item{
      margin-right: 40px;
      font-size: 13px;
      letter-spacing: .05em;
      @media (max-width: $BREAKPOINT) {
        font-size: 12px;
      }
      &:last-of-type{
        margin-right: 0;
      }
      @media (max-width: $BREAKPOINT) {
        margin-bottom: 30px;
        margin-right: 0;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    &__link{
      &::after {
        display: inline-block;
        @include _ix-icon(\e90b);
        margin-left: 5px;
        font-size: 11px;
        text-decoration: none;
        color: $COLOR-02;
      }
    }
  }
}

#common-middle-footer-under {
  .p-footer-nav-2 {
    width: 1056px;
    margin: 0 auto;
    display: flex;
    border-top: solid 1px #4e4e4e;
    justify-content: flex-end;
    //flex-direction: row-reverse;
    height: 55px;
    margin-bottom: 24px;
    align-items: center;
    @media (max-width: $BREAKPOINT) {
      width: 100%;
      flex-direction: column-reverse;
      min-width: initial;
      height: auto;
      border: none;
      margin-bottom: 0;
    }
    .p-footer-nav-2__col-1 {
      @media (max-width: $BREAKPOINT) {
        padding: 20px 0;
        margin: 0;
        img {
          margin: 0;
        }
      }
    }
    .p-footer-nav-2__col-2 {
      @media (max-width: $BREAKPOINT) {
        width: 100%;
      }
    }
  }
  .p-footer-nav-2__list {
    display: flex;
    @media (max-width: $BREAKPOINT) {
      margin: 0 !important;
      flex-wrap: wrap;
    }
    a:hover {
      text-decoration: none;
    }
  }
  .p-footer-nav-2__list li {
    // font-size: 1.3rem;
    font-size: 12px;
    letter-spacing: 0.05em;
    margin-left: 14px;
    @media (max-width: $BREAKPOINT) {
      width: 50%;
      flex-basis: 50%;
      margin: 0;
      // border-bottom: solid 1px #4e4e4e;
      border: none;
    }
    &::after {
      display: none;
    }
    &:first-of-type {
      margin-left: 0;
    }
    a {
      @media (max-width: $BREAKPOINT) {
        //font-size: 1.2rem;
        font-size: 12px;
        display: block;
        line-height: 45px;
        height: 45px;
        border-bottom: solid 1px #4e4e4e;
        padding: 0 10px;
        &:nth-child(2n-1) {
          border-right: solid 1px #4e4e4e;
        }

        &.u-use--sp {
          border-right: solid 1px #4e4e4e;
        }
      }
      &::after {
        display: none;
      }
    }
  }


  .p-footer-nav-2.row-2 {
    @media (min-width: $BREAKPOINT+1) {
      height: auto;
      .p-footer-nav-2__col-2 {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .p-footer-nav-2__list {
        flex-wrap: wrap;
        margin-top: -14px;

         li {
          margin-top: 14px;
          margin-left: 0;
          margin-right: 14px;
         }
      }
    }
  }
}







//シンプルフッター
#common-light-footer,
#foot-item .ix-l-footer-2 {
  width: 100%;
  background: #fff;
  border-top: solid 1px #eee;
  border-bottom: none;
  .ix-l-footer-2__inner {
    width: 100%;
  }
  .p-footer-corp {
    display: flex;
    width: 1056px;
    min-width: initial;
    margin: 0 auto;
    height: 58px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $BREAKPOINT) {
      height: 54px;
      width: 100% !important;
      min-width: initial;
      padding: 0 10px;
      flex-direction: row-reverse;
    }
  }
  .p-footer-corp__copyright {
    color: #9fa0a0;
    font-size: 13px;
    letter-spacing: 0.02em;
    @media (max-width: $BREAKPOINT) {
      text-align: right;
      padding-top: 0;
      font-size: 12px;
      letter-spacing: 0.02em;
    }
  }
  .p-footer-corp__logo {
    @media (max-width: $BREAKPOINT) {
      width: 116px;
    }
    img {
      @media (max-width: $BREAKPOINT) {
        width: 100%;
      }
    }
  }
}
#common-light-footer {
  border: none;
}

@media (max-width: $BREAKPOINT) {
  .u-use--pc {
    display: none !important;
  }
}
@media (min-width: $BREAKPOINT+1) {
  .u-use--sp {
    display: none !important;
  }
}
//会員登録系のページ
#foot-item .ix-l-footer-2 {
  .p-footer-corp {
    width: 100%;
    max-width: 1056px;
    padding: 0;
    @media (max-width: $BREAKPOINT) {
      height: 54px;
      width: 100%;
      min-width: initial;
      padding: 0 10px;
    }
  }
}

//ページトップ
.ix-l-footer-2 {
  position: relative;
}
.js-pagetop {
  display: block;
  width: 50px;
  height: 50px;
  background-color: rgba(62, 62, 62, 0.05);
  position: fixed;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: none;
  @media (max-width: $BREAKPOINT) {
    height: 40px;
    width: 40px;
  }
  &:hover {
    opacity: 0.7;
  }
  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border-top: 1px solid #c9caca;
    border-right: 1px solid #c9caca;
    transform: rotate(-45deg);
    margin: 22px auto;
    overflow: hidden;
    @media (max-width: $BREAKPOINT) {
      height: 14px;
      width: 14px;
      margin: 18px auto;
    }
  }
}

//動的向けのアコーディオンアイコン

#foot-item {
  @media (max-width: $BREAKPOINT) {
    .p-gnav__col .p-gnav__ttl::after {
      font-family: "sharp-icon" !important;
      content: "\e909";
      line-height: 1em;
      font-size: 24px;
      height: 24px;
      width: 24px;
    }
    // .p-gnav__col .p-gnav__ttl.is-active::after {
    //     color: #b99969;
    //     transform: rotate(-90deg);
    // }
  }
}

//静的トップページ向けにパンくずを非表示
.is-site-top .p-footer {
  display: none;
}

//静的ページヘッダメニュー用
@media (max-width: $BREAKPOINT) {
  .is-responsive .p-gnav__ttl {
    position: relative;
  }
}

//静的ページフッターの罫線追加
@media (max-width: $BREAKPOINT) {
  .footer #foot-item #common-middle-footer-under .p-footer-nav-2__list {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #4e4e4e;
    }
  }
}

/* パンくず・ナビ p,a   iX p,a */
// #foot-item .ft-main p, #foot-item #common-middle-footer p {
//     color: #fff;
// }
// #foot-item .ft-main a, #foot-item #common-middle-footer a {
//     color: #fff;
// }
// #foot-item .ft-main a:hover, #foot-item #common-middle-footer a:hover {
//     text-decoration: underline;
// }

// /* パンくず*/
// #foot-item .ft-main #f-breadcrumb .f-breadcrumb-item {
//     line-height: 1.5em;
// }
// #foot-item > .ft-main > .ft-row {
//     margin-bottom: 32px !important;
// }

/* ナビ */

// #foot-item .ft-main #common-full-footer .ft-nav-item-parent {
//     font-family: "YuGothic Medium","游ゴシック Medium",YuGothic,"游ゴシック","游ゴシック体","Noto Sans Japanese Light",sans-serif;
// }

// #foot-item .ft-main #common-full-footer .ft-nav-parent-txt {
//     font-size: 15px;
//     font-weight: 700;
//     line-height: 1.5em;
//     margin-bottom: 28px !important;
// }

// #foot-item .ft-main #common-full-footer .ft-nav-item-child li:not(:last-child) {
//     margin-bottom: 18px;
// }

// /* iX */
// #foot-item #common-middle-footer .ft-row {
//     background: #3e3e3e;
// }

// #foot-item #common-middle-footer .ft-logo-area {
//     border-top: 1px solid #4e4e4e;
//     padding: 10px 0;
// }

// #foot-item #common-middle-footer .f-site-logo {

// }

// .ft-sub-list-item {
//     padding-right: 16px;
// }

// #foot-item #common-middle-footer .ft-sub-list-item:after {
//     display: none;
// }

// #foot-item #common-middle-footer .ft-sub-list-item a {
//     letter-spacing: 0.8px;
// }

// #foot-item #common-middle-footer .ft-sub-list-item a:after {
//     display: none;
// }

// /* 全体 */
// #foot-item .f-bg-color {
//     background: #3e3e3e;
// }


.ix-l-footer .ix-l-group-footer {
  background-color: $COLOR-02;
  .p-gnav {
    width: 1056px;
    margin: 0 auto;
    padding: 45px 0 60px;
    border-bottom: 1px solid #ccc;

    &__col {
      min-width: 190px;

      &.p-gnav__col-col3 {
        min-width: 509px;
      }

      &:not(:first-child) {
        margin-left: 45px;
      }
    }

    &__ttl {
      color: $COLOR-01;
      .p-gnav__ttl-text {
        color: $COLOR-01;
        font-size: 15px;

        &::before {
          @include _ix-icon(\e908);
          font-size: 13px;
          color: #ccc;
          margin-right: 5px;
        }

        &:hover {
          color: #9FA0A0;
        }

        &.pc-none {
          display: none;
        }
        &.sp-none {
          display: block;
        }
      }
    }

    &__list {
      margin-top: 30px;
      li {
        color: $COLOR-01;
        font-size: 14px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      .p-gnav__subttl {
        color: $COLOR-01;
        font-weight: bold;
        margin-bottom: 18px;
      }
      .p-gnav__list-item {
        display: flex;

        > li {
          &:nth-child(2) {
            margin-left: 15px;
          }
          &:nth-child(3) {
            margin-left: 20px;
          }
        }
      }
    }

    a {
      color: $COLOR-01;

      &:hover {
        color: #9FA0A0;
      }
    }
  }

  .sp-link {
    display: none;
  }
}

@media (max-width: 667px) {
  .ix-l-footer .ix-l-group-footer {
    .p-gnav {
      width: 100%;
      min-width: initial;
      margin-bottom: 0 !important;
      padding: 0;
      border: none;


      .p-gnav__col {
        border-bottom: none;
  
        &:not(:first-child) {
          margin-left: 0;
        }
        &.p-gnav__col-col3 {
          min-width: auto;
        }
      }


      .p-gnav__ttl {
        border-top: none;
        height: 55px;
        border-bottom: 1px solid #E5E5E5;
        .p-gnav__ttl-text {
          font-size: 13px;

          &::before {
            display: none;
          }
          &.pc-none {
            display: block;
          }
          &.sp-none {
            display: none;
          }

          &:hover {
            color: #222;
          }
        }

        &::after {
          color: #CCCCCC;
        }

        &.is-active {
          border-bottom: none;
          &::after {
            color: #CCCCCC;
          }
        }
      }

      .p-gnav__list {
        margin-top: 0;
        border-top: 1px solid #E5E5E5;

        li {
          margin: 0;
          font-size: 13px;
          a {
            display: block;
            height: 55px;
            line-height: 55px;
            padding: 0 25px;
            background-color: #F5F5F5;
            border-bottom: 1px solid #E5E5E5;

            &:hover {
              background-color: #E5E5E5;
              color: $COLOR-01;
            }
          }
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .p-gnav__list-item {
          display: block;
  
          > li {
            &:nth-child(2) {
              margin-left: 0;
            }
            &:nth-child(3) {
              margin-left: 0;
            }
          }

          .p-gnav__subttl {
            margin-bottom: 0;
            height: 55px;
            line-height: 55px;
            padding: 0 25px;
            background-color: #F5F5F5;
            font-size: 13px;
          }
        }
      }
    }

    .sp-link {
      display: block;
    }
  }

}