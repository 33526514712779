@charset "UTF-8";
#common-middle-footer-upper .p-fotter-service__link::after, .ix-l-footer .ix-l-group-footer .p-gnav__ttl .p-gnav__ttl-text::before {
  font-family: 'ix-tenshoku' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 667px) {
  footer.footer {
    width: 100%;
    min-width: initial;
  }
}

#include-footer {
  min-width: 1200px;
  background-color: #3e3e3e;
  padding-top: 45px;
  position: relative;
  z-index: 900;
}

@media (max-width: 667px) {
  #include-footer {
    padding: 0;
    min-width: initial;
  }
}

#foot-item,
.ix-l-footer {
  background-color: #3e3e3e;
}

#foot-item a,
.ix-l-footer a {
  text-decoration: none;
}

#foot-item a:hover,
.ix-l-footer a:hover {
  color: #9fa0a0;
  text-decoration: none;
}

#foot-item *,
.ix-l-footer * {
  color: #fff;
  font-family: "YuGothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック", "游ゴシック体", "Noto Sans Japanese Light", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1;
}

@media (max-width: 667px) {
  #foot-item .ft-row .inner,
  .ix-l-footer .ft-row .inner {
    width: 100%;
    min-width: initial;
  }
}

#foot-item .f-bg-color,
.ix-l-footer .f-bg-color {
  padding-top: 45px;
  background-color: #3e3e3e;
}

@media (max-width: 667px) {
  #foot-item .f-bg-color,
  .ix-l-footer .f-bg-color {
    padding: 0;
    display: block;
  }
}

#foot-item .f-bg-color .ft-row,
.ix-l-footer .f-bg-color .ft-row {
  width: 1056px;
  margin: 0 auto;
}

@media (max-width: 667px) {
  #foot-item .f-bg-color .ft-row,
  .ix-l-footer .f-bg-color .ft-row {
    width: 100%;
    min-width: initial;
    margin-bottom: 0 !important;
  }
}

#foot-item #pagetop-pc,
.ix-l-footer #pagetop-pc {
  width: 50px;
  height: 50px;
  bottom: 58px;
}

.ix-l-footer * {
  color: #fff;
  font-family: "YuGothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック", "游ゴシック体", "Noto Sans Japanese Light", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.ix-l-footer .ix-l-footer-1 {
  width: 1056px;
  margin: 0 auto;
}

@media (max-width: 667px) {
  .ix-l-footer .ix-l-footer-1 {
    width: 100%;
    min-width: initial;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 667px) {
  .ix-l-footer .ix-l-footer-1 .ix-l-footer-1__inner {
    padding: 0;
  }
}

.ix-l-footer .p-gnav__col .p-gnav__ttl::after {
  bottom: 3px;
  right: 16px;
}

.p-footer__topicpath {
  margin-bottom: 40px;
}

#f-breadcrumb {
  font-size: 13px;
  display: flex;
}

@media (max-width: 667px) {
  #f-breadcrumb {
    display: none;
  }
}

#f-breadcrumb .f-breadcrumb-item {
  margin-right: 15px;
}

#f-breadcrumb .f-breadcrumb-item a {
  display: flex;
  padding-right: 15px;
}

#f-breadcrumb .f-breadcrumb-item::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  bottom: 0;
  right: 0;
  width: 4px;
  height: 4px;
  border-top: 1px solid #bfc0c0;
  border-right: 1px solid #bfc0c0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#f-breadcrumb .f-breadcrumb-item:last-child::after {
  display: none;
}

#ix-topicpath-footer {
  font-size: 13px;
  display: flex;
}

@media (max-width: 667px) {
  #ix-topicpath-footer {
    display: none;
  }
}

#ix-topicpath-footer ul li {
  margin-right: 15px;
  position: relative;
}

#ix-topicpath-footer ul li a {
  display: flex;
  padding-right: 15px;
}

#ix-topicpath-footer ul li::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  bottom: 0;
  right: 0;
  width: 4px;
  height: 4px;
  border-top: 1px solid #bfc0c0;
  border-right: 1px solid #bfc0c0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#ix-topicpath-footer ul li:last-child:after {
  display: none;
}

#common-full-footer {
  width: 1056px;
  margin: 0 auto;
}

@media (max-width: 667px) {
  #common-full-footer {
    width: 100%;
  }
}

#common-full-footer .p-gnav {
  display: flex;
  margin-bottom: 70px;
}

@media (max-width: 667px) {
  #common-full-footer .p-gnav {
    display: block;
    margin-bottom: 0;
  }
}

#common-full-footer .p-gnav .p-gnav__col {
  width: 264px;
}

@media (max-width: 667px) {
  #common-full-footer .p-gnav .p-gnav__col {
    width: 100%;
    font-size: 13px;
    border: none;
  }
}

#common-full-footer .p-gnav .p-gnav__ttl {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 30px;
}

@media (max-width: 667px) {
  #common-full-footer .p-gnav .p-gnav__ttl {
    padding: 0 10px;
    height: 55px;
    line-height: 55px;
    font-weight: normal;
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #4e4e4e;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 667px) {
  #common-full-footer .p-gnav .p-gnav__list {
    border-bottom: 1px solid #4e4e4e;
    border-top: none;
    display: none;
    padding: 0;
  }
}

#common-full-footer .p-gnav .p-gnav__list li {
  font-size: 14px;
  margin-bottom: 20px;
}

@media (max-width: 667px) {
  #common-full-footer .p-gnav .p-gnav__list li {
    margin: 0;
  }
}

#common-full-footer .p-gnav .p-gnav__list li:last-child {
  margin-bottom: 0;
}

@media (max-width: 667px) {
  #common-full-footer .p-gnav .p-gnav__list li {
    margin-bottom: 0;
  }
}

#common-full-footer .p-gnav .p-gnav__list li a {
  transition: none;
}

@media (max-width: 667px) {
  #common-full-footer .p-gnav .p-gnav__list li a {
    display: block;
    padding: 0 25px;
    height: 45px;
    line-height: 45px;
    font-size: 13px;
    margin-bottom: 0;
    background-color: #484848;
    border-bottom: none;
  }
  #common-full-footer .p-gnav .p-gnav__list li a::after {
    display: none;
  }
}

@media (max-width: 667px) {
  .p-gnav__col .p-gnav__ttl::after {
    content: "\e908";
    display: block;
    font-size: 12px;
    height: 10px;
    width: 10px;
    color: #727171;
    transform-origin: center;
    transform: rotate(90deg);
    transition: all 0.3s ease 0s;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 10px;
  }
  .p-gnav__col .p-gnav__ttl.is-active::after {
    color: #b99969;
    transform: rotate(-90deg);
  }
}

@media (max-width: 667px) {
  #common-middle-footer {
    display: flex;
    flex-direction: column-reverse;
  }
}

#common-middle-footer-upper {
  background: #3e3e3e;
  margin-bottom: 20px;
}

@media (max-width: 667px) {
  #common-middle-footer-upper {
    margin-bottom: 0;
  }
}

#common-middle-footer-upper .p-footer-nav-2 {
  width: 1056px;
  margin: 0 auto;
  display: flex;
  border: none;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 667px) {
  #common-middle-footer-upper .p-footer-nav-2 {
    width: 100%;
    flex-direction: column-reverse;
    min-width: initial;
    height: auto;
    border: none;
  }
}

@media (max-width: 667px) {
  #common-middle-footer-upper .p-footer-nav-2 .p-footer-nav-2__col-1 {
    padding: 35px 0 20px;
    margin: 0;
  }
  #common-middle-footer-upper .p-footer-nav-2 .p-footer-nav-2__col-1 img {
    margin: 0;
  }
}

@media (max-width: 667px) {
  #common-middle-footer-upper .p-footer-nav-2 .p-footer-nav-2__col-2 {
    width: 100%;
  }
}

#common-middle-footer-upper .c-logo-footer {
  height: auto;
  width: 216px;
  position: static;
}

@media (max-width: 667px) {
  #common-middle-footer-upper .c-logo-footer {
    width: 144px;
  }
}

#common-middle-footer-upper .c-logo-footer img {
  width: 100%;
}

@media print, screen and (min-width: 668px) {
  #common-middle-footer-upper .c-logo-footer img {
    width: 70%;
  }
}

@media (max-width: 667px) {
  #common-middle-footer-upper .p-fotter-service {
    width: 100%;
    padding: 36px 18px;
    border-bottom: 1px solid #4e4e4e;
  }
}

#common-middle-footer-upper .p-fotter-service__list {
  display: flex;
  padding-top: 12px;
}

@media (max-width: 667px) {
  #common-middle-footer-upper .p-fotter-service__list {
    display: block;
    padding-top: 0px;
  }
}

#common-middle-footer-upper .p-fotter-service__item {
  margin-right: 40px;
  font-size: 13px;
  letter-spacing: .05em;
}

@media (max-width: 667px) {
  #common-middle-footer-upper .p-fotter-service__item {
    font-size: 12px;
  }
}

#common-middle-footer-upper .p-fotter-service__item:last-of-type {
  margin-right: 0;
}

@media (max-width: 667px) {
  #common-middle-footer-upper .p-fotter-service__item {
    margin-bottom: 30px;
    margin-right: 0;
  }
  #common-middle-footer-upper .p-fotter-service__item:last-of-type {
    margin-bottom: 0;
  }
}

#common-middle-footer-upper .p-fotter-service__link::after {
  display: inline-block;
  content: "\e90b";
  margin-left: 5px;
  font-size: 11px;
  text-decoration: none;
  color: #fff;
}

#common-middle-footer-under .p-footer-nav-2 {
  width: 1056px;
  margin: 0 auto;
  display: flex;
  border-top: solid 1px #4e4e4e;
  justify-content: flex-end;
  height: 55px;
  margin-bottom: 24px;
  align-items: center;
}

@media (max-width: 667px) {
  #common-middle-footer-under .p-footer-nav-2 {
    width: 100%;
    flex-direction: column-reverse;
    min-width: initial;
    height: auto;
    border: none;
    margin-bottom: 0;
  }
}

@media (max-width: 667px) {
  #common-middle-footer-under .p-footer-nav-2 .p-footer-nav-2__col-1 {
    padding: 20px 0;
    margin: 0;
  }
  #common-middle-footer-under .p-footer-nav-2 .p-footer-nav-2__col-1 img {
    margin: 0;
  }
}

@media (max-width: 667px) {
  #common-middle-footer-under .p-footer-nav-2 .p-footer-nav-2__col-2 {
    width: 100%;
  }
}

#common-middle-footer-under .p-footer-nav-2__list {
  display: flex;
}

@media (max-width: 667px) {
  #common-middle-footer-under .p-footer-nav-2__list {
    margin: 0 !important;
    flex-wrap: wrap;
  }
}

#common-middle-footer-under .p-footer-nav-2__list a:hover {
  text-decoration: none;
}

#common-middle-footer-under .p-footer-nav-2__list li {
  font-size: 12px;
  letter-spacing: 0.05em;
  margin-left: 14px;
}

@media (max-width: 667px) {
  #common-middle-footer-under .p-footer-nav-2__list li {
    width: 50%;
    flex-basis: 50%;
    margin: 0;
    border: none;
  }
}

#common-middle-footer-under .p-footer-nav-2__list li::after {
  display: none;
}

#common-middle-footer-under .p-footer-nav-2__list li:first-of-type {
  margin-left: 0;
}

@media (max-width: 667px) {
  #common-middle-footer-under .p-footer-nav-2__list li a {
    font-size: 12px;
    display: block;
    line-height: 45px;
    height: 45px;
    border-bottom: solid 1px #4e4e4e;
    padding: 0 10px;
  }
  #common-middle-footer-under .p-footer-nav-2__list li a:nth-child(2n-1) {
    border-right: solid 1px #4e4e4e;
  }
  #common-middle-footer-under .p-footer-nav-2__list li a.u-use--sp {
    border-right: solid 1px #4e4e4e;
  }
}

#common-middle-footer-under .p-footer-nav-2__list li a::after {
  display: none;
}

@media (min-width: 668px) {
  #common-middle-footer-under .p-footer-nav-2.row-2 {
    height: auto;
  }
  #common-middle-footer-under .p-footer-nav-2.row-2 .p-footer-nav-2__col-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #common-middle-footer-under .p-footer-nav-2.row-2 .p-footer-nav-2__list {
    flex-wrap: wrap;
    margin-top: -14px;
  }
  #common-middle-footer-under .p-footer-nav-2.row-2 .p-footer-nav-2__list li {
    margin-top: 14px;
    margin-left: 0;
    margin-right: 14px;
  }
}

#common-light-footer,
#foot-item .ix-l-footer-2 {
  width: 100%;
  background: #fff;
  border-top: solid 1px #eee;
  border-bottom: none;
}

#common-light-footer .ix-l-footer-2__inner,
#foot-item .ix-l-footer-2 .ix-l-footer-2__inner {
  width: 100%;
}

#common-light-footer .p-footer-corp,
#foot-item .ix-l-footer-2 .p-footer-corp {
  display: flex;
  width: 1056px;
  min-width: initial;
  margin: 0 auto;
  height: 58px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 667px) {
  #common-light-footer .p-footer-corp,
  #foot-item .ix-l-footer-2 .p-footer-corp {
    height: 54px;
    width: 100% !important;
    min-width: initial;
    padding: 0 10px;
    flex-direction: row-reverse;
  }
}

#common-light-footer .p-footer-corp__copyright,
#foot-item .ix-l-footer-2 .p-footer-corp__copyright {
  color: #9fa0a0;
  font-size: 13px;
  letter-spacing: 0.02em;
}

@media (max-width: 667px) {
  #common-light-footer .p-footer-corp__copyright,
  #foot-item .ix-l-footer-2 .p-footer-corp__copyright {
    text-align: right;
    padding-top: 0;
    font-size: 12px;
    letter-spacing: 0.02em;
  }
}

@media (max-width: 667px) {
  #common-light-footer .p-footer-corp__logo,
  #foot-item .ix-l-footer-2 .p-footer-corp__logo {
    width: 116px;
  }
}

@media (max-width: 667px) {
  #common-light-footer .p-footer-corp__logo img,
  #foot-item .ix-l-footer-2 .p-footer-corp__logo img {
    width: 100%;
  }
}

#common-light-footer {
  border: none;
}

@media (max-width: 667px) {
  .u-use--pc {
    display: none !important;
  }
}

@media (min-width: 668px) {
  .u-use--sp {
    display: none !important;
  }
}

#foot-item .ix-l-footer-2 .p-footer-corp {
  width: 100%;
  max-width: 1056px;
  padding: 0;
}

@media (max-width: 667px) {
  #foot-item .ix-l-footer-2 .p-footer-corp {
    height: 54px;
    width: 100%;
    min-width: initial;
    padding: 0 10px;
  }
}

.ix-l-footer-2 {
  position: relative;
}

.js-pagetop {
  display: block;
  width: 50px;
  height: 50px;
  background-color: rgba(62, 62, 62, 0.05);
  position: fixed;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: none;
}

@media (max-width: 667px) {
  .js-pagetop {
    height: 40px;
    width: 40px;
  }
}

.js-pagetop:hover {
  opacity: 0.7;
}

.js-pagetop::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-top: 1px solid #c9caca;
  border-right: 1px solid #c9caca;
  transform: rotate(-45deg);
  margin: 22px auto;
  overflow: hidden;
}

@media (max-width: 667px) {
  .js-pagetop::before {
    height: 14px;
    width: 14px;
    margin: 18px auto;
  }
}

@media (max-width: 667px) {
  #foot-item .p-gnav__col .p-gnav__ttl::after {
    font-family: "sharp-icon" !important;
    content: "\e909";
    line-height: 1em;
    font-size: 24px;
    height: 24px;
    width: 24px;
  }
}

.is-site-top .p-footer {
  display: none;
}

@media (max-width: 667px) {
  .is-responsive .p-gnav__ttl {
    position: relative;
  }
}

@media (max-width: 667px) {
  .footer #foot-item #common-middle-footer-under .p-footer-nav-2__list {
    position: relative;
  }
  .footer #foot-item #common-middle-footer-under .p-footer-nav-2__list::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #4e4e4e;
  }
}

/* パンくず・ナビ p,a   iX p,a */
/* ナビ */
.ix-l-footer .ix-l-group-footer {
  background-color: #fff;
}

.ix-l-footer .ix-l-group-footer .p-gnav {
  width: 1056px;
  margin: 0 auto;
  padding: 45px 0 60px;
  border-bottom: 1px solid #ccc;
}

.ix-l-footer .ix-l-group-footer .p-gnav__col {
  min-width: 190px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__col.p-gnav__col-col3 {
  min-width: 509px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__col:not(:first-child) {
  margin-left: 45px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__ttl {
  color: #222;
}

.ix-l-footer .ix-l-group-footer .p-gnav__ttl .p-gnav__ttl-text {
  color: #222;
  font-size: 15px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__ttl .p-gnav__ttl-text::before {
  content: "\e908";
  font-size: 13px;
  color: #ccc;
  margin-right: 5px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__ttl .p-gnav__ttl-text:hover {
  color: #9FA0A0;
}

.ix-l-footer .ix-l-group-footer .p-gnav__ttl .p-gnav__ttl-text.pc-none {
  display: none;
}

.ix-l-footer .ix-l-group-footer .p-gnav__ttl .p-gnav__ttl-text.sp-none {
  display: block;
}

.ix-l-footer .ix-l-group-footer .p-gnav__list {
  margin-top: 30px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__list li {
  color: #222;
  font-size: 14px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__list li:not(:last-child) {
  margin-bottom: 15px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__list .p-gnav__subttl {
  color: #222;
  font-weight: bold;
  margin-bottom: 18px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__list .p-gnav__list-item {
  display: flex;
}

.ix-l-footer .ix-l-group-footer .p-gnav__list .p-gnav__list-item > li:nth-child(2) {
  margin-left: 15px;
}

.ix-l-footer .ix-l-group-footer .p-gnav__list .p-gnav__list-item > li:nth-child(3) {
  margin-left: 20px;
}

.ix-l-footer .ix-l-group-footer .p-gnav a {
  color: #222;
}

.ix-l-footer .ix-l-group-footer .p-gnav a:hover {
  color: #9FA0A0;
}

.ix-l-footer .ix-l-group-footer .sp-link {
  display: none;
}

@media (max-width: 667px) {
  .ix-l-footer .ix-l-group-footer .p-gnav {
    width: 100%;
    min-width: initial;
    margin-bottom: 0 !important;
    padding: 0;
    border: none;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__col {
    border-bottom: none;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__col:not(:first-child) {
    margin-left: 0;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__col.p-gnav__col-col3 {
    min-width: auto;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl {
    border-top: none;
    height: 55px;
    border-bottom: 1px solid #E5E5E5;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl .p-gnav__ttl-text {
    font-size: 13px;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl .p-gnav__ttl-text::before {
    display: none;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl .p-gnav__ttl-text.pc-none {
    display: block;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl .p-gnav__ttl-text.sp-none {
    display: none;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl .p-gnav__ttl-text:hover {
    color: #222;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl::after {
    color: #CCCCCC;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl.is-active {
    border-bottom: none;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__ttl.is-active::after {
    color: #CCCCCC;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list {
    margin-top: 0;
    border-top: 1px solid #E5E5E5;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list li {
    margin: 0;
    font-size: 13px;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list li a {
    display: block;
    height: 55px;
    line-height: 55px;
    padding: 0 25px;
    background-color: #F5F5F5;
    border-bottom: 1px solid #E5E5E5;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list li a:hover {
    background-color: #E5E5E5;
    color: #222;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list li:not(:last-child) {
    margin-bottom: 0;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list .p-gnav__list-item {
    display: block;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list .p-gnav__list-item > li:nth-child(2) {
    margin-left: 0;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list .p-gnav__list-item > li:nth-child(3) {
    margin-left: 0;
  }
  .ix-l-footer .ix-l-group-footer .p-gnav .p-gnav__list .p-gnav__list-item .p-gnav__subttl {
    margin-bottom: 0;
    height: 55px;
    line-height: 55px;
    padding: 0 25px;
    background-color: #F5F5F5;
    font-size: 13px;
  }
  .ix-l-footer .ix-l-group-footer .sp-link {
    display: block;
  }
}
